import { IBaseItems } from "@/interfaces/item";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: IBaseItems[] = [];

const slicer = createSlice({
  name: "items",
  initialState: initialState,
  reducers: {
    addItems(state, action: PayloadAction<IBaseItems[]>) {
      state = [...action.payload];
      return state;
    },
    removeItemById(state, action: PayloadAction<{ id: string }>) {
      state = state.filter((item) => item.id !== action.payload.id);
      return state;
    },
  },
});
export const { addItems, removeItemById } = slicer.actions;
export default slicer.reducer;
