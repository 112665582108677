import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export const initialState: User = {
  id: "",
  api_key: "",
  balance: 0,
  trade_url: null,
  email: null,
  steam_id: "",
  selling_rate: null,
  status: null,
  email_verified: null,
  sync_steam_status_at: null,
  lock_trade: null,
  hold_trade: null,
  name: "",
  steam_name: "",
  avatar: "",
  provider: "",
  steam_created_at: null,
  country_code: null,
  created_at: new Date(),
  updated_at: new Date(),
};

const slicer = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    changeUserProps(state: any, action: PayloadAction<User>) {
      state = action.payload;
      return state;
    },
  },
});
export const { changeUserProps } = slicer.actions;
export default slicer.reducer;
