import { IItems } from "@/interfaces/item";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: IItems[] = [];

const slicer = createSlice({
  name: "itemsByUserId",
  initialState: initialState,
  reducers: {
    addListItem(state, action: PayloadAction<IItems[]>) {
      state = [...action.payload];
      return state;
    },
    deleteItemById(state, action: PayloadAction<{ id: string }>) {
      state = state.filter((item) => item.id !== action.payload.id);
      return state;
    },
  },
});
export const { addListItem, deleteItemById } = slicer.actions;
export default slicer.reducer;
