import React, { ReactComponentElement } from "react";

interface IButton {
  onClick?: (a: any) => void;
  icon?: JSX.Element;
  className?: string;
  name: string | number | JSX.Element;
  disable?: boolean;
}

export default function Button(props: IButton) {
  return (
    <button
      disabled={props.disable}
      onClick={props.onClick}
      className={`flex items-center gap-2 uppercase text-white font-semibold text-xs rounded-md border-b-2 border-l-2 px-8 py-2 ease-in-out duration-300 ${props.className}`}
    >
      {props.icon ? (
        <span className="w-4 -mr-1 my-auto">{props.icon}</span>
      ) : null}
      {props.name}
    </button>
  );
}
