import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";

interface ISearchBar {
  onChange?: (e: any) => void;
}
export default function SearchBar(props: ISearchBar) {
  const { onChange } = props;

  return (
    <div>
      <div className="flex w-full flex-wrap items-stretch h-9">
        <input
          type="search"
          className="h-full relative m-0 -mr-px block w-[1%] min-w-0 flex-auto rounded-l bg-gray-600 bg-clip-padding px-3 py-1.5 font-semibold text-gray-500 outline-none transition duration-300 ease-in-out focus:text-gray-200 focus:outline-none dark:text-neutral-200 dark:placeholder:text-neutral-200"
          placeholder="Search"
          aria-label="Search"
          aria-describedby="button-addon1"
          onChange={(e) => onChange && onChange(e)}
        />
        <button
          className="h-full relative z-[2] flex items-center rounded-r bg-gray-700 py-1.5 px-2.5 text-xs font-medium uppercase leading-tight shadow-md transition duration-150 ease-in-out hover:bg-gray-900"
          type="button"
          id="button-addon1"
          data-te-ripple-init
          data-te-ripple-color="light"
        >
          <MagnifyingGlassIcon className="w-4 text-gray-300" />
        </button>
      </div>
    </div>
  );
}
