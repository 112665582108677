export const provider = {
  STEAM: "steam",
};

export const currency = [
  {
    value: "USD",
    name: "USD",
  },
  {
    value: "VND",
    name: "VND",
  },
];

export const SELL_STATUS = {
  ON_HOLD: "ON_HOLD",
  SELLING: "SELLING",
};

export const LIMIT = 24;

export const CONDITION = {
  FN: "FN",
  MW: "MW",
  FT: "FT",
  WW: "WW",
  BS: "BS",
};
export const ORDER_ASC = "asc";
export const ORDER_DESC = "desc";

export enum UserRole {
  ADMIN = "admin",
  USER = "user",
}

export enum TYPE_INPUT {
  TRADE_URL = "trade_url",
  API_KEY = "api_key",
  EMAIL = "email",
  PHONE_NUMBER = "phone_number",
}

export const WeaponBase = [
  {
    name: "pistol",
    options: [""],
  },
  { name: "rifle", options: [""] },
  { name: "sniper rifle", options: [""] },
  { name: "smg", options: [""] },
  { name: "machinegun", options: [""] },
  { name: "shotgun", options: [""] },
  { name: "knife", options: [""] },
  { name: "gloves", options: [""] },
  { name: "container", options: [""] },
  { name: "sticker", options: [""] },
  { name: "other", options: [""] },
];
