import { IItems } from "@/interfaces/item";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: IItems[] = [];

const slicer = createSlice({
  name: "sell_items",
  initialState: initialState,
  reducers: {
    addSellItems(state, action: PayloadAction<IItems>) {
      const isFound = state.some((element) => {
        if (element.id === action.payload.id) {
          return true;
        }
        return false;
      });
      if (!isFound) {
        state = state.concat(action.payload);
      }
      return state;
    },
    removeItemById(state, action: PayloadAction<{ id: string }>) {
      state = state.filter((item) => item.id !== action.payload.id);
      return state;
    },

    customPriceItems(
      state,
      action: PayloadAction<{ id: any; newPrice: number }>
    ) {
      const itemIndex = state.findIndex(
        (item) => item.id === action.payload.id
      );
      state[itemIndex].selling_price = action.payload.newPrice;
      return state;
    },
  },
});
export const { addSellItems, removeItemById, customPriceItems } =
  slicer.actions;
export default slicer.reducer;
