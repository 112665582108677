import { combineReducers } from "redux";
import cart from "./reducers/cart";
import cartVisibility from "./reducers/cart_visibility";
import items from "./reducers/items";
import sellItems from "./reducers/sell_items";
import user from "./reducers/user";
import itemByUserId from "./reducers/itemByUserId";
import search from "./reducers/search";

const rootReducer = combineReducers({
  cart,
  items,
  cartVisibility,
  user,
  sellItems,
  itemByUserId,
  search,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
