import axios from "axios";

export function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

export function isValidDateTime(str: string) {
  const date = new Date(str);
  return !isNaN(date.getTime());
}

export const commonFetcher = (url: string) =>
  axios
    .get(`${process.env.NEXT_PUBLIC_BASE_URL}` + url)
    .then((res) => {
      return res.data;
    })
    .catch((err) => err.response);
