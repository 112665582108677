import { Fragment } from "react";
import Head from "next/head";
import NavBar from "../navbar";
import { useRouter } from "next/router";
import Sidebar from "../admin/sidebar";
import { req } from "@/lib/axios";
import { changeUserProps } from "@/redux/reducers/user";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { RedSpinner } from "../loading/spinner";
import { SpeedInsights } from "@vercel/speed-insights/next";

const MainLayout = ({ children }: any) => {
  const route = useRouter();

  const [isLoading, setIsLoading] = useState(true);
  const dp = useDispatch();

  const fetchProfile = async () => {
    try {
      const res = await req.get("/api/users/me");
      if (res.status != 200 || res.data == null) {
        setIsLoading(false);
        return;
      }
      dp(changeUserProps(res.data));
    } catch (error) {
      console.log("error", error);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    fetchProfile();
  }, []);
  return isLoading ? (
    <RedSpinner />
  ) : (
    <Fragment>
      <Head>
        <title>Skin-Kicks</title>
        <meta name="description" content="Generated by create next app" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      {route.pathname.split("/")[1] === "admin" ? (
        <Fragment>
          <div className="w-full bg-gray-300 min-h-screen">
            <div className="h-96 relative bg-gray-900"></div>
            <div className=" w-full grid grid-cols-12 shadow-md min-h-screen absolute top-0">
              <div className="col-span-2">
                <Sidebar />
              </div>
              <div className="py-9 px-10 w-full text-white col-span-10">
                {children}
              </div>
            </div>
          </div>
        </Fragment>
      ) : (
        <Fragment>
          <NavBar />
          <div className="w-full bg-gray-900 min-h-screen">
            <div className="bg-gray-900 max-w-screen-2xl mx-auto">
              <div className="px-4 py-4">{children}</div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

export default MainLayout;
