import { IItems } from "@/interfaces/item";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: IItems[] = [];

const slicer = createSlice({
  name: "cart",
  initialState: initialState,
  reducers: {
    addCartItem(state, action: PayloadAction<IItems>) {
      const isFound = state.some((element) => {
        if (element.id === action.payload.id) {
          return true;
        }
        return false;
      });
      if (!isFound) {
        state = state.concat(action.payload);
      }
      return state;
    },
    removeItemCartById(state, action: PayloadAction<{ id: string }>) {
      state = state.filter((item) => item.id !== action.payload.id);
      return state;
    },
  },
});
export const { addCartItem, removeItemCartById } = slicer.actions;
export default slicer.reducer;
