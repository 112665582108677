import axios from "axios";
import Cookies from "js-cookie";
export const req = axios.create({
  baseURL: process.env.NEXT_PUBLIC_BASE_URL || "http://localhost:8082",
});

// Add a request interceptor
req.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    // Add Authorization header if token cookie is set

    const token = Cookies.get("token");
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
req.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error

    if (error.response.status === 401) {
      return error.response;
    }

    return Promise.reject(error);
  }
);
