import { req } from "@/lib/axios";
import { RootState } from "@/redux";
import { changeCartVisible } from "@/redux/reducers/cart_visibility";
import { changeUserProps, initialState } from "@/redux/reducers/user";
import { classNames } from "@/utils";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import {
  Bars3Icon,
  Bars4Icon,
  BellIcon,
  ShoppingCartIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { PlusCircleIcon } from "@heroicons/react/24/solid";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import Coin from "../../../public/coin.png";
import SteamLogo from "../../../public/steam.svg";
import Button from "../button";
import SearchBar from "../input/searchbar";
import { searchItemHomePage } from "@/redux/reducers/search";
import FilterItem from "@/container/itemDetailPage/FilterItem";

export default function NavBar() {
  const open = useSelector((state: RootState) => state.cartVisibility);
  const userInfo = useSelector((state: RootState) => state.user);
  const dp = useDispatch();
  const handleClickCart = () => {
    dp(changeCartVisible(!open));
  };

  const router = useRouter();

  const loginHandler = async () => {
    // get request to server
    const res = await req.get("/auth/steam");

    // redirect to steam login page
    router.push(res.data.url);
  };

  const logoutHandler = async () => {
    const res = await req.post("/auth/steam/signout");
    if (res.status != 200 || res.data == null) {
      return;
    }
    dp(changeUserProps(initialState));
  };

  const onChangeSearch = (keyword: any) => {
    switch (router.pathname) {
      case "/":
        dp(searchItemHomePage(keyword));
        break;
      default:
        break;
    }
  };

  return (
    <Disclosure as="nav" className="bg-gray-800 w-full z-50">
      {({ open }) => (
        <>
          <div className="px-6 sm:px-6 lg:px-8">
            <div className="flex h-16 items-center justify-between">
              <div className="flex items-center w-1/3">
                <div className="flex-shrink-0">
                  <Link href="/">
                    <div className="relative h-10 w-10 block lg:hidden">
                      <Image
                        className=""
                        src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                        alt="Your icon"
                        fill={true}
                      />
                    </div>
                  </Link>
                  <Link href="/">
                    <div className="relative h-10 w-10 hidden lg:block">
                      <Image
                        className=""
                        src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                        alt="Your icon"
                        fill={true}
                      />
                    </div>
                  </Link>
                </div>
              </div>

              <div className="hidden sm:ml-6 sm:block w-1/3">
                <div className="flex space-x-4 justify-center">
                  <Link
                    href="#"
                    className="rounded-md uppercase bg-gray-900 px-3 py-2 text-sm font-medium text-white"
                  >
                    Store
                  </Link>
                  <Link
                    href="/sell"
                    className="rounded-md uppercase px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
                  >
                    Sell
                  </Link>
                  <Link
                    href="#"
                    className="rounded-md uppercase px-3 py-2 text-sm font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
                  >
                    Support
                  </Link>
                </div>
              </div>
              <div className="hidden sm:ml-6 sm:block w-1/3 md:flex md:flex-row-reverse">
                {userInfo.steam_id === "" ? (
                  <Button
                    name="login"
                    icon={<Image src={SteamLogo} alt="steam-logo" />}
                    className="bg-rose-500 border-rose-800 hover:bg-rose-700"
                    onClick={loginHandler}
                  />
                ) : (
                  <div className="flex items-center gap-3">
                    <Link href="/topup">
                      <div className="font-semibold flex items-center gap-3 text-white text-base bg-gray-900 px-3 py-1 rounded-md border-2 border-rose-700">
                        <PlusCircleIcon className="h-5 text-rose-700" />
                        {userInfo.balance}
                        <Image
                          src={Coin}
                          alt="coin"
                          width={20}
                          height={20}
                          className="-ml-2.5"
                        />
                      </div>
                    </Link>
                    <button
                      type="button"
                      className="rounded-full bg-gray-800 text-gray-400 hover:text-white"
                    >
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                    <button
                      type="button"
                      className="rounded-full bg-gray-800 text-gray-400 hover:text-white"
                    >
                      <span className="sr-only">View cart</span>
                      <ShoppingCartIcon
                        onClick={handleClickCart}
                        className="h-6 w-6"
                        aria-hidden="true"
                      />
                    </button>

                    {/* Profile dropdown */}
                    <Menu as="div" className="relative ml-3">
                      <div>
                        <Menu.Button className="flex rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                          <span className="sr-only">Open user menu</span>
                          <div className="relative h-8 w-8">
                            <Image
                              className="w-6 rounded-full"
                              src={
                                userInfo.avatar ||
                                "https://avatars.akamai.steamstatic.com/fef49e7fa7e1997310d705b2a6158ff8dc1cdfeb_full.jpg"
                              }
                              alt=""
                              fill={true}
                            />
                          </div>
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                href={`/player/${userInfo.steam_id}`}
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                Your Profile
                              </Link>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                href="#"
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                Settings
                              </Link>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {({ active }) => (
                              <Link
                                onClick={() => logoutHandler()}
                                href="#"
                                className={classNames(
                                  active ? "bg-gray-100" : "",
                                  "block px-4 py-2 text-sm text-gray-700"
                                )}
                              >
                                Sign out
                              </Link>
                            )}
                          </Menu.Item>
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                )}
              </div>

              <div className="-mr-2 flex sm:hidden">
                <Disclosure.Button className="inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="sr-only">Open main menu</span>
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
            </div>

            <div className="text-gray-200 flex flex-col text-sm font-bold divide-y-2 divide-gray-700">
              <div className="cursor-pointer">
                <FilterItem />
              </div>

              <div className="py-3">
                <SearchBar onChange={(e) => onChangeSearch(e.target.value)} />
              </div>
            </div>
          </div>

          <Disclosure.Panel className="sm:hidden">
            <div className="space-y-1 px-2 pt-2 pb-3">
              {/* Current: "bg-gray-900 text-white", Default: "text-gray-300 hover:bg-gray-700 hover:text-white" */}
              <Disclosure.Button
                as="a"
                href="/"
                className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
              >
                x
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                href="/sell"
                className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
              >
                Sell
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                href="/"
                className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
              >
                Support
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                href="/transactions"
                className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
              >
                Waiting to receive
              </Disclosure.Button>
              <Disclosure.Button
                as="a"
                href="#"
                className="block rounded-md px-3 py-2 text-base font-medium text-gray-300 hover:bg-gray-700 hover:text-white"
              >
                History
              </Disclosure.Button>
            </div>

            <div className="border-t border-gray-700 pt-4 pb-3">
              {userInfo.steam_id === "" ? (
                <Button
                  name="login"
                  icon={<Image src={SteamLogo} alt="steam-logo" />}
                  className="bg-rose-500 border-rose-800 hover:bg-rose-700 mx-4"
                  onClick={loginHandler}
                />
              ) : (
                <>
                  <div className="flex items-center px-5">
                    <div className="flex-shrink-0">
                      <div className="relative h-10 w-10 ">
                        <Image
                          className="rounded-full"
                          src={userInfo.avatar}
                          alt={userInfo.name}
                          fill={true}
                        />
                      </div>
                    </div>
                    <div className="ml-3">
                      <div className="text-base font-medium text-white">
                        {userInfo.steam_name}
                      </div>
                      <div className="text-sm font-medium text-gray-400">
                        {userInfo.email || ""}
                      </div>
                    </div>
                    <div>
                      {" "}
                      <button
                        type="button"
                        className="ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                      >
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                      </button>
                      <button
                        type="button"
                        className="ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                      >
                        <span className="sr-only">View cart</span>
                        <ShoppingCartIcon
                          onClick={handleClickCart}
                          className="h-6 w-6"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </div>
                  <div className="mt-3 space-y-1 px-2">
                    <Disclosure.Button
                      as="a"
                      href="/topup"
                      className="flex gap-2 items-center rounded-md border-2 border-rose-700 px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                    >
                      Balance: <span>{userInfo.balance}</span>
                      <Image
                        src={Coin}
                        alt="coin"
                        width={20}
                        height={20}
                        className="-ml-1.5"
                      />
                    </Disclosure.Button>
                    <Disclosure.Button
                      as="a"
                      href={`/player/${userInfo.steam_id}`}
                      className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                    >
                      Your Profile
                    </Disclosure.Button>
                    <Disclosure.Button
                      as="a"
                      href="#"
                      className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                    >
                      Settings
                    </Disclosure.Button>
                    <Disclosure.Button
                      as="a"
                      href="#"
                      className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                    >
                      Sign out
                    </Disclosure.Button>
                  </div>
                </>
              )}
            </div>
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
}
