import {
  DocumentChartBarIcon,
  PresentationChartBarIcon,
  UserCircleIcon,
} from "@heroicons/react/24/solid";
import Link from "next/link";
import { useRouter } from "next/router";
import React from "react";

export default function Sidebar() {
  const router = useRouter();
  return (
    <nav className="md:left-0 md:block md:fixed md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl bg-white flex flex-wrap items-center justify-between relative md:w-64 z-10 py-4 px-6">
      <div className="md:flex-col md:items-stretch md:min-h-full md:flex-nowrap px-0 flex flex-wrap items-center justify-between w-full mx-auto">
        {/* Toggler */}
        <button
          className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
          type="button"
          // onClick={() => setCollapseShow("bg-white m-2 py-3 px-6")}
        >
          <i className="fas fa-bars"></i>
        </button>
        {/* Brand */}
        <Link
          href="/admin/dashboard"
          className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
        >
          Skin-Kicks
        </Link>

        {/* Collapse */}
        <div
          className={
            "md:flex md:flex-col md:items-stretch md:opacity-100 md:relative md:mt-4 md:shadow-none shadow absolute top-0 left-0 right-0 z-40 overflow-y-auto overflow-x-hidden h-auto items-center flex-1 rounded "
          }
        >
          {/* Collapse header */}
          <div className="md:min-w-full md:hidden block pb-4 mb-4 border-b border-solid border-blueGray-200">
            <div className="flex flex-wrap">
              <div className="w-6/12">
                <Link
                  href="/"
                  className="md:block text-left md:pb-2 text-blueGray-600 mr-0 inline-block whitespace-nowrap text-sm uppercase font-bold p-4 px-0"
                >
                  Skin-Kicks
                </Link>
              </div>
              <div className="w-6/12 flex justify-end">
                <button
                  type="button"
                  className="cursor-pointer text-black opacity-50 md:hidden px-3 py-1 text-xl leading-none bg-transparent rounded border border-solid border-transparent"
                  // onClick={() => setCollapseShow("hidden")}
                >
                  <i className="fas fa-times"></i>
                </button>
              </div>
            </div>
          </div>
          {/* Form */}
          <form className="mt-6 mb-4 md:hidden">
            <div className="mb-3 pt-0">
              <input
                type="text"
                placeholder="Search"
                className="border-0 px-3 py-2 h-12 border border-solid  border-blueGray-500 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-base leading-snug shadow-none outline-none focus:outline-none w-full font-normal"
              />
            </div>
          </form>
          {/* Divider */}
          <hr className="my-4 md:min-w-full" />
          {/* Heading */}
          <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
            Manage System
          </h6>
          {/* Navigation */}
          <ul className="md:flex-col md:min-w-full flex flex-col list-none">
            <li className="items-center">
              <Link
                href="/admin/dashboard"
                className={
                  "text-xs uppercase py-3 font-bold flex gap-2 items-center " +
                  (router.pathname.indexOf("/admin/dashboard") !== -1
                    ? "text-rose-500 hover:text-rose-600"
                    : "text-gray-700 hover:text-gray-500")
                }
              >
                <PresentationChartBarIcon
                  className={`w-5 h-5${
                    router.pathname.indexOf("/admin/dashboard") !== -1
                      ? "text-rose-500 hover:text-rose-600"
                      : "text-gray-700 hover:text-gray-500"
                  }`}
                />
                Dashboard
              </Link>
            </li>

            <li className="items-center">
              <Link
                href="/admin/users"
                className={
                  "text-xs uppercase py-3 font-bold flex gap-2 items-center  " +
                  (router.pathname.indexOf("/admin/users") !== -1
                    ? "text-rose-500 hover:text-rose-600"
                    : "text-gray-700 hover:text-gray-500")
                }
              >
                <UserCircleIcon
                  className={`w-5 h-5${
                    router.pathname.indexOf("/admin/users") !== -1
                      ? "text-rose-500 hover:text-rose-600"
                      : "text-gray-700 hover:text-gray-500"
                  }`}
                />
                Users
              </Link>
            </li>
            <li className="items-center">
              <Link
                href="/admin/transactions"
                className={
                  "text-xs uppercase py-3 font-bold flex gap-2 items-center  " +
                  (router.pathname.indexOf("/admin/transactions") !== -1
                    ? "text-rose-500 hover:text-rose-600"
                    : "text-gray-700 hover:text-gray-500")
                }
              >
                <DocumentChartBarIcon
                  className={`w-5 h-5${
                    router.pathname.indexOf("/admin/transactions") !== -1
                      ? "text-rose-500 hover:text-rose-600"
                      : "text-gray-700 hover:text-gray-500"
                  }`}
                />
                Transactions
              </Link>
            </li>
          </ul>
          {/* Divider */}
          <hr className="my-4 md:min-w-full" />
          {/* Heading */}
          <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
            Auth Layout Pages
          </h6>
          {/* Navigation */}
          <ul className="md:flex-col md:min-w-full flex flex-col list-none md:mb-4">
            <li className="items-center">
              <Link
                href="/auth/login"
                className="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
              >
                <i className="fas fa-fingerprint text-blueGray-400 mr-2 text-sm"></i>{" "}
                Login
              </Link>
            </li>

            <li className="items-center">
              <Link
                href="/auth/login"
                className="text-blueGray-700 hover:text-blueGray-500 text-xs uppercase py-3 font-bold block"
              >
                <i className="fas fa-fingerprint text-blueGray-400 mr-2 text-sm"></i>{" "}
                Login
              </Link>
            </li>
          </ul>

          <hr className="my-4 md:min-w-full" />
          {/* Heading */}
          <h6 className="md:min-w-full text-blueGray-500 text-xs uppercase font-bold block pt-1 pb-4 no-underline">
            Documentation
          </h6>
        </div>
      </div>
    </nav>
  );
}
