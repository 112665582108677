import DropDown, { TailwindDropdown } from "@/component/dropdown";
import { WeaponBase } from "@/constant";
import React from "react";

export default function FilterItem() {
  return (
    <div className="flex ">
      {WeaponBase.map((item, idx: number) => (
        <TailwindDropdown
          key={idx}
          title={item.name.toUpperCase()}
          options={item.options}
        />
      ))}
    </div>
  );
}
