import MainLayout from "@/component/layout/main";
import rootReducer from "@/redux";
import "@/styles/globals.css";
import { configureStore } from "@reduxjs/toolkit";
import type { AppProps } from "next/app";
import { Provider } from "react-redux";
import { useCronitor } from "@cronitorio/cronitor-rum-nextjs";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { useRouter } from "next/router";

export const store = configureStore({
  reducer: rootReducer,
});

export default function App({ Component, pageProps }: AppProps) {
  const rumApiKey = process.env.NEXT_PUBLIC_RUM_API_KEY || "";
  useCronitor(rumApiKey);

  const router = useRouter();

  return (
    <Provider store={store}>
      <MainLayout>
        <Component {...pageProps} />
      </MainLayout>

      <SpeedInsights route={router.pathname} />
    </Provider>
  );
}
