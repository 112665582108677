import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: boolean = false;

const slicer = createSlice({
  name: "cart_visibility",
  initialState: initialState,
  reducers: {
    changeCartVisible(state, action: PayloadAction<boolean>) {
      return !state;
    },
  },
});
export const { changeCartVisible } = slicer.actions;
export default slicer.reducer;
