import { classNames } from "@/utils";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon, PlayIcon } from "@heroicons/react/24/solid";
import { Fragment } from "react";

interface IDropDown {
  title: string;
  options: string[];
}

export default function DropDown(props: IDropDown) {
  const { title, options } = props;
  return (
    <div>
      <button className="flex items-center gap-2 text-sm peer px-4 py-2 font-semibold rounded-md bg-gray-900 hover:bg-gray-800 text-gray-200 ease-in-out duration-300">
        {title} <PlayIcon className="h-2 rotate-90" />
      </button>

      <div
        className="hidden peer-hover:flex
   flex-col bg-gray-800 drop-shadow-lg text-sm text-gray-200 rounded-tr-md rounded-br-md"
      >
        {options.map((option, index) => (
          <a className="px-3 py-2 hover:bg-gray-700" href="#" key={index}>
            {option}
          </a>
        ))}
      </div>
    </div>
  );
}

export function TailwindDropdown(props: IDropDown) {
  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 px-3 py-2 rounded-md bg-gray-800 hover:bg-gray-900 text-gray-200 shadow-sm ring-1 ring-inset ring-gray-800 ">
          {props.title}
          <ChevronDownIcon
            className="-mr-1 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-gray-800  shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {props.options.map((element: String, idx: number) => (
              <Menu.Item key={idx}>
                {({ active }) => (
                  <a
                    href="#"
                    className={classNames(
                      active ? "bg-gray-800 text-gray-200" : "text-gray-300",
                      "block px-4 py-2 text-sm hover:bg-gray-900 transition-all"
                    )}
                    onClick={() => console.log(element)}
                  >
                    {element}
                  </a>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
