import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: string = "";

const slicer = createSlice({
  name: "search",
  initialState: initialState,
  reducers: {
    searchItemHomePage(state, action: PayloadAction) {
      return action.payload;
    },
  },
});
export const { searchItemHomePage } = slicer.actions;
export default slicer.reducer;
